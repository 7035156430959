import React, { useState, useEffect } from "react";
import "./App.css";
import logo from "./assets/LogoWithPron.png";
import logoAndroid from "./assets/android.png";
import logoIos from "./assets/ios.png";
import { getMobileOperatingSystem } from "./utils";
import { FadeLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import i18n from "../src/i18n/index";

function App() {
    const [os, setOs] = useState("");
    const [loading, setLoading] = useState(true);
    const { innerHeight: height } = window;
    const [slug, setSlug] = useState("");
    const [url, setUrl] = useState("");
    const [type, setType] = useState("");
    const { t } = useTranslation();

    useEffect(() => {
        const res = getMobileOperatingSystem();
        setOs(res);
        const currentUrl = window.location.href;
        const a = currentUrl.split("/").length;
        setSlug(currentUrl.split("/")[a - 1]);
        setUrl(currentUrl);

        if (currentUrl.indexOf("meeting") !== -1) {
            setType("meeting");
        } else {
            setType("community");
        }
        let userLang = navigator.language || navigator.userLanguage;
        if (userLang.indexOf("es") !== -1) {
            i18n.changeLanguage("es");
        }
        setLoading(false);
    }, []);

    return (
        <div className="container">
            {loading ? (
                <div
                    style={{
                        height: height - 60,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <FadeLoader color="#EC408B" />
                </div>
            ) : (
                <div className="container">
                    <h2 className="title mb-5">
                        {type === "meeting"
                            ? t("callInvitation")
                            : t("communityInvitation")}
                    </h2>
                    <img className="logo mb-5" src={logo} alt="logo" />
                    <p className="text-normal">{t("downloadInvitation")}</p>
                    <div className="btn-container mb-5">
                        <a
                            href="https://play.google.com/store/apps/details?id=com.girolabs.amoofy&pli=1"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="img-btn"
                                src={logoAndroid}
                                alt="logoAndroid"
                            />
                        </a>
                        <a
                            href="https://apps.apple.com/us/app/amoofy/id6473188823"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <img
                                className="img-btn"
                                src={logoIos}
                                alt="logoIos"
                            />
                        </a>
                    </div>
                    <p className="text-normal">{t("goToWeb")}</p>
                    <a
                        href="https://amoofy.com"
                        target="_blank"
                        rel="noreferrer"
                    >
                        www.amoofy.com
                    </a>
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                    {slug !== "" ? (
                        <div className="fixed">
                            <p className="text-normal">{t("haveTheApp")}</p>
                            {os === "Android" ? (
                                <a
                                    href={`amoofy://${type}/${slug}`}
                                    className="btn"
                                >
                                    {type === "meeting"
                                        ? t("callCTA")
                                        : t("communityCTA")}
                                </a>
                            ) : os === "iOS" ? (
                                <a href={url} className="btn">
                                    {type === "meeting"
                                        ? t("callCTA")
                                        : t("communityCTA")}
                                </a>
                            ) : (
                                <p>{t("mobileDevice")}</p>
                            )}
                        </div>
                    ) : null}
                </div>
            )}
        </div>
    );
}

export default App;
